/**
 * ANGULAR CORE
 */
import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

/**
 * COMPONENTE PRINCIPAL
 */
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

/**
 * IONIC
 */
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SuperTabsModule} from '@ionic-super-tabs/angular';

/**
 * FIREBASE
 */
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getFunctions, provideFunctions} from '@angular/fire/functions';
import {getStorage, provideStorage} from '@angular/fire/storage';

/**
 * PRIMENG
 */
import {PanelModule} from 'primeng/panel';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToolbarModule} from 'primeng/toolbar';
import {MenuModule} from 'primeng/menu';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {InputNumberModule} from 'primeng/inputnumber';
import {BadgeModule} from 'primeng/badge';
import {OverlayPanelModule} from 'primeng/overlaypanel';

/**
 * MATERIAL
 */
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';

/**
 * CONTROLLER
 */
import {ImagesController} from './controllers/images.controller';
import {ObserverController} from './controllers/observer.controller';
import {ShopCartController} from './controllers/shopcart.controller';

/**
 * OTROS
 */
import {environment} from 'src/environments/environment';
import {GoogleMapsModule} from '@angular/google-maps';
import {HelperService} from 'helpers/helper.service';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MensajesModule} from './reutilizables/mensajes/mensajes.module';
import {AuthInterceptor} from './auth.interceptor';
import {CreateAccountDialogPageModule} from './pages/index/dialog/create-account-dialog/create-account-dialog.module';
import {LoginAccountDialogPageModule} from './pages/index/dialog/login-account-dialog/login-account-dialog.module';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';

//prettier-ignore
@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		IonicModule.forRoot(),
		SuperTabsModule.forRoot(),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideAuth(() => getAuth()),
		provideFirestore(() => getFirestore()),
		provideStorage(() => getStorage()),
		provideFunctions(() => getFunctions()),
		PanelModule,
		TieredMenuModule,
		ToolbarModule,
		MenuModule,
		DropdownModule,
		ButtonModule,
		DialogModule,
		MatSidenavModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatSelectModule,
		MatToolbarModule,
		MatIconModule,
		GoogleMapsModule,
		HttpClientModule,
		ToastModule,
		ConfirmDialogModule,
		InputNumberModule,
		BadgeModule,
		OverlayPanelModule,
		NgxSpinnerModule,
		MensajesModule,
		CreateAccountDialogPageModule,
		LoginAccountDialogPageModule,
		NgxExtendedPdfViewerModule,
	],
	providers: [
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		ImagesController,
		ObserverController,
		ShopCartController,
		MessageService,
		ConfirmationService,
		DialogService,
		HelperService,
		Title
	],
	bootstrap: [AppComponent]
})
export class AppModule {}

